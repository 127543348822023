export const shortenText = (text, length) => {
  if (text.length <= length) {
    return text;
  }

  const lastWord = text.slice(0, length - 3).lastIndexOf(" ");
  const cutoff = lastWord === -1 ? length - 3 : lastWord;

  return `${text.slice(0, cutoff)}...`;
};
