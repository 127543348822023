import React from "react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import Layout from "../components/layout/layout";
import { shortenText } from "../utils/shortenText";

import "./styling/narrators.css";

const Narrators = ({ pageContext, location, data }) => {
  const { pathname } = location;
  const intl = useIntl();

  return (
    <Layout
      display="true"
      path={pathname}
      title={intl.formatMessage({ id: "narrator.title" })}
    >
      <StaticImage
        className="narratorHeroImage"
        alt={intl.formatMessage({ id: "narrator.heroImage" })}
        src="../assets/narrators/cover-microphone.jpg"
      />
      <div className="seperator"></div>
      <h1 className="narratorPageHeader">
        <FormattedMessage id="narrator.Header" />
      </h1>
      <div className="narrators">
        {data.allNarrator.nodes.map((narrator) => (
          <Narrator key={narrator.id} narrator={narrator} />
        ))}
      </div>
      <div className="fillContainer" />
    </Layout>
  );
};

const Narrator = ({ narrator: { timezone, bio, name, userImage } }) => (
  <div className="narrator">
    <div className="nameTimeContainer">
      <h2>{name}</h2>
      <p>{timezone}</p>
    </div>
    <div className="imageDescContainer">
      <GatsbyImage
        imgClassName="narratorImage"
        className="narratorImage"
        image={getImage(userImage)}
        alt={name}
      />
      <p>{shortenText(bio, 150)}</p>
    </div>
  </div>
);

export default Narrators;

export const pageQuery = graphql`
  query {
    allNarrator {
      nodes {
        userImage {
          childImageSharp {
            gatsbyImageData(width: 120, height: 120, placeholder: BLURRED)
          }
        }
        bio
        id
        name
        timezone
      }
    }
  }
`;
